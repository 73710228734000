import Pubnub, { MessageEvent } from "pubnub";
import { useCallback, useEffect, useId, useRef, useState } from "react";
import { ACCUMELATED_USAGE_RATE, NINA_STATUS } from "models/nina";
import { PUBNUB_BOTTLE_DATA, PUBNUB_DATA, PUBNUB_MESSAGE_EVENT } from "models/pubnub";
import { USER } from "models/user";
import { useToast } from "components/ui/useToast";

const { REACT_APP_PUBNUB_SUBSCRIBE_KEY } = process.env;
type PubnubProps = {
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setNinaStatus: React.Dispatch<React.SetStateAction<NINA_STATUS | null>>;
  setAccumulatedUsageRate: React.Dispatch<React.SetStateAction<ACCUMELATED_USAGE_RATE | null>>;
  setUser: React.Dispatch<React.SetStateAction<USER>>;
  setUsageTimes: React.Dispatch<React.SetStateAction<number>>;
};

export const usePubnub = ({
  setShowLoader,
  setNinaStatus,
  setAccumulatedUsageRate,
  setUser,
  setUsageTimes,
}: PubnubProps) => {
  const [currentChannel, setCurrentChannel] = useState<string | null>(null);
  const pubnubClient = useRef<Pubnub | null>(null);
  const [ninaMessageEvent, setNinaMessageEvent] = useState<PUBNUB_MESSAGE_EVENT | null>(null);
  const statusEventHandlerRef = useRef<any | null>(null);
  const messageEventHandlerRef = useRef<any | null>(null);
  const uuid = useId();
  const { toast } = useToast();

  const isPubnubBottleData = (object: PUBNUB_DATA): object is PUBNUB_BOTTLE_DATA => {
    return "bottle" in object;
  };

  const disconnectFromNinaChannel = useCallback(() => {
    if (!pubnubClient.current) return;
    if (!currentChannel) pubnubClient.current.unsubscribeAll();
    else {
      pubnubClient.current.unsubscribe({
        channels: [currentChannel],
      });
    }
    pubnubClient.current.removeListener({ message: handleMessage });
    setCurrentChannel(null);
    setNinaMessageEvent(null);
    setNinaStatus(null);
    console.log("Specific NINA channel is disconnected");
  }, [currentChannel]);

  const connectToNinaChannel = useCallback(
    (channel: string) => {
      return new Promise((resolve, reject) => {
        if (!pubnubClient.current || !channel) {
          reject(new Error("PubNub client or channel is missing."));
          return;
        }
        setCurrentChannel((prev) => {
          if (!!prev && !!pubnubClient.current) {
            pubnubClient.current.unsubscribe({
              channels: [prev],
            });
            setNinaMessageEvent(null);
            console.log("Specific NINA channel is disconnected");
          }
          return channel;
        });

        // Subscribe to the PubNub channel
        pubnubClient.current.subscribe({ channels: [channel], withPresence: true });

        function handleStatusEvent(statusEvent: Pubnub.StatusEvent) {
          if (statusEvent.category === "PNConnectedCategory") {
            // PubNub channel is connected
            console.log("Specific NINA channel is connected");
            resolve("connected");
            if (pubnubClient.current && statusEventHandlerRef.current) {
              pubnubClient.current.removeListener(statusEventHandlerRef.current);
            }
          }
        }
        statusEventHandlerRef.current = { status: handleStatusEvent };
        pubnubClient.current.addListener(statusEventHandlerRef.current);
      });
    },
    [currentChannel]
  );

  const handleStatusActiveMount = useCallback(
    (data: PUBNUB_DATA) => {
      setNinaStatus(data.state);
      if ("accumulatedUsageRate" in data) {
        if (data.accumulatedUsageRate) {
          setAccumulatedUsageRate(data.accumulatedUsageRate);
        }
      }
      setShowLoader(false);
    },
    [setAccumulatedUsageRate, setNinaStatus, setShowLoader]
  );
  const handleStatusStandby = useCallback(
    (data: PUBNUB_DATA) => {
      setNinaStatus(data.state);
      disconnectFromNinaChannel();
      toast({
        title: "Error",
        description: "Nina device dismounted, Please place NINA back on a bottle",
        variant: "destructive",
      });
    },
    [setNinaStatus, disconnectFromNinaChannel, toast]
  );
  const handleStatusStandbyMount = useCallback(
    (data: PUBNUB_DATA) => {
      if (!isPubnubBottleData(data)) {
        setNinaStatus(data.state);
        return;
      }
      if (data.accumulatedUsageRate) {
        setAccumulatedUsageRate({
          pouringSessionPouredAmount: data.accumulatedUsageRate.pouringSessionPouredAmount,
          pouringSessionsTotalCost: data.accumulatedUsageRate.pouringSessionsTotalCost,
          tabTotalCost: data.accumulatedUsageRate.tabTotalCost,
        });
        setUsageTimes((prevNumber) => ++prevNumber);
        disconnectFromNinaChannel();
        setNinaStatus(NINA_STATUS.FINISH_POUR);
      }
      if (data.userPerks) {
        setUser((prevUser) => {
          return { ...prevUser, userPerks: data.userPerks };
        });
      }

      setShowLoader(false);
    },
    [setNinaStatus, setShowLoader]
  );
  const handleStatusPouring = useCallback(
    (data: PUBNUB_DATA) => {
      setNinaStatus(data.state);
    },
    [setNinaStatus]
  );
  const handleError = useCallback(
    (data: PUBNUB_DATA) => {
      setNinaStatus(data.state);
      disconnectFromNinaChannel();
      toast({ title: "Something went wrong", description: "Please try again", variant: "destructive" });
    },
    [setNinaStatus, disconnectFromNinaChannel]
  );

  const handleMessage = useCallback(({ message }: MessageEvent) => {
    setNinaMessageEvent(message);
  }, []);

  // Handle Nina Message Events
  useEffect(() => {
    if (!currentChannel || !ninaMessageEvent) return;

    const { data } = ninaMessageEvent;
    console.log("Specific NINA event: ", data);
    const { state } = data;
    const statusHandlers: Record<NINA_STATUS | "default", (data: PUBNUB_DATA) => void> = {
      [NINA_STATUS.ACTIVE_MOUNT]: handleStatusActiveMount,
      [NINA_STATUS.STAND_BY]: handleStatusStandby,
      [NINA_STATUS.STAND_BY_MOUNT]: handleStatusStandbyMount,
      [NINA_STATUS.FINISH_POUR]: () => {},
      [NINA_STATUS.POURING]: handleStatusPouring,
      [NINA_STATUS.ERROR]: handleError,
      default: () => {
        setNinaStatus(null);
        disconnectFromNinaChannel();
        toast({
          title: "Something went wrong",
          description: "We're experiencing some issues, Please try again",
          variant: "destructive",
        });
      },
    };
    statusHandlers[state] ? statusHandlers[state](data) : statusHandlers.default(data);
  }, [
    currentChannel,
    handleStatusActiveMount,
    handleStatusPouring,
    handleStatusStandbyMount,
    ninaMessageEvent,
    handleStatusStandby,
    handleError,
  ]);

  // Initialize pubnub
  useEffect(() => {
    pubnubClient.current = new Pubnub({
      subscribeKey: REACT_APP_PUBNUB_SUBSCRIBE_KEY!,
      uuid: uuid,
    });
    if (messageEventHandlerRef.current) {
      pubnubClient.current.removeListener(messageEventHandlerRef.current);
      messageEventHandlerRef.current = { message: handleMessage };
    } else {
      messageEventHandlerRef.current = { message: handleMessage };
    }
    pubnubClient.current.addListener(messageEventHandlerRef.current);
    return () => {
      if (!pubnubClient.current) return;
      pubnubClient.current.removeListener(messageEventHandlerRef.current);
    };
  }, [handleMessage]);

  return { connectToNinaChannel, disconnectFromNinaChannel };
};
