import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { APPLICATION_ROUTE } from "../models/appRoute";

export const useApplicationStatus = () => {
  const { search } = useLocation();
  const applicationRoute: APPLICATION_ROUTE = useMemo(() => {
    return {
      start: { route: "/" + search },
    };
  }, [search]);

  return {
    applicationRoute,
  };
};
