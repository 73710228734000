import { CREDENTIALS } from "models/socials";
import { getCodeOTP, verifyCodeOTP, getSocialLoginToken, getUserTokenIsVerify, postGuestUser } from "./http.service";
import { persistentService } from "./persistent.service";

const sendCodeSms = async (phoneNumber: string, isoCode: string) => getCodeOTP(phoneNumber, isoCode);

const verifyCode = async (
  code: string,
  phoneNumber: string,
  isoCode: string,
  spaceId: string,
  redirectedFrom?: string,
  uuid?: string | null
) => verifyCodeOTP(phoneNumber, isoCode, code, spaceId, redirectedFrom, uuid);

const sendSocialLoginToken = async (
  credentials: CREDENTIALS,
  socialBrand: string,
  spaceId: string,
  redirectedFrom?: string,
  uuid?: string | null
) => {
  const result = await getSocialLoginToken({ credentials, socialBrand, spaceId, redirectedFrom, uuid: uuid || null });
  persistentService.setItem("token", result.token);
  return result;
};
const createGuestUser = async (spaceId: number) => postGuestUser(spaceId);

const reconnectingUser = async (token: string, spaceId: number, uuid?: string | null) =>
  getUserTokenIsVerify(token, spaceId, uuid);

export const loginService = {
  sendCodeSms,
  verifyCode,
  sendSocialLoginToken,
  reconnectingUser,
  createGuestUser,
};
