import { useEffect } from "react";

const OfflinePage = () => {
  useEffect(() => {
    document.title = "Nina-Offline";
    return () => {
      document.title = "Nina";
    };
  }, []);
  return (
    <main className="h-screen w-screen px-4 flex flex-col justify-center items-center text-center bg-background">
      <div className="font-bold text-2xl text-primary">It seems like you're offline...</div>
      <div className="text-primary-text"> Please make sure your internet connection is enabled</div>
    </main>
  );
};

export default OfflinePage;
