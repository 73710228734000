import { Suspense, lazy, useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { SplashScreen } from "./pages/SplashScreen";
import useIsOnline from "hooks/useIsOnline";
import useWakeLock from "hooks/useWakeLock";
import OfflinePage from "pages/OfflinePage";
import { Fallback, Layout } from "pages/Layout";
import LazyLoginBottomSheet from "components/Modals/Lazy/LazyLoginBottomSheet";
import LazyOTPSheet from "components/Modals/Lazy/LazyOTPSheet";
import LazyPaymentSheet from "components/Modals/Lazy/LazyPaymentSheet";
import LazyCreditCardSheet from "components/Modals/Lazy/LazyCreditCardSheet";
const SliderDrinks = lazy(() => import("pages/SliderDrinks"));
const NoSpaceSelected = lazy(() => import("pages/NoSpaceSelected"));

function App() {
  const [startedSplash, setStartedSplash] = useState<boolean>(false);
  const { isOnline } = useIsOnline(); // In case of no network
  useWakeLock(); // Avoid screen sleep

  return isOnline ? (
    <>
      <SplashScreen setSplashStarted={setStartedSplash} />
      {startedSplash && (
        <Suspense fallback={<Fallback />}>
          <Routes>
            <Route element={<Layout />}>
              <Route element={<NoSpaceSelected />} path={"/selectSpace"} />
              <Route element={<SliderDrinks />} path={"/"} />
            </Route>
          </Routes>

          <LazyLoginBottomSheet />
          <LazyOTPSheet />
          <LazyPaymentSheet />
          <LazyCreditCardSheet />
        </Suspense>
      )}
    </>
  ) : (
    <Routes>
      <Route path="/offline" element={<OfflinePage />} />
    </Routes>
  );
}

export default App;
