import { useBottomSheetContext } from "context/BottomSheetContext";
import React, { Suspense, useEffect, useState } from "react";
const CreditCardSheet = React.lazy(() => import("../CreditCardBottomSheet"));

const LazyCreditCardSheet = () => {
  const { showCreditCard } = useBottomSheetContext();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (showCreditCard && !isVisible) {
      setIsVisible(true);
    }
  }, [showCreditCard]);

  if (!isVisible) return null;
  return (
    <Suspense fallback={null}>
      <CreditCardSheet />
    </Suspense>
  );
};

export default LazyCreditCardSheet;
