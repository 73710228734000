import React from "react";
import { ReactComponent as NinaLogo } from "assets/images/ninaLogo.svg";
import { useLocationContext } from "context/LocationContext";
import HamburgerMenu from "./HamburgerMenu";

export const Header: React.FC = () => {
  const { location } = useLocationContext();

  return (
    <header className="flex justify-between items-center w-full py-[10px] px-4">
      <HamburgerMenu />

      {location?.logoUrl ? (
        <img alt="logo" src={location.logoUrl} className="w-[70px] h-[35px] object-contain" />
      ) : (
        <NinaLogo
          className="active:opacity-60 w-[70px] h-[35px] object-contain"
          onClick={() => {
            window.open("https://www.drinknina.com/", "_blank");
          }}
        />
      )}
    </header>
  );
};
