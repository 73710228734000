import { PERK } from "./perks";

export interface USER {
  phoneNumber: string;
  email: string;
  id: number | null;
  loginStatus: LOGIN_STATUS;
  firstName: string | null;
  lastName: string | null;
  identityToken: string;
  isFeedbackGiven?: boolean;
  userPerks: PERK[];
  preLoginPerks: PERK[] | null;
}

export interface USER_LOGIN_DETAILS {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  isFeedbackGiven?: boolean;
  userPerks: PERK[];
}
export type UserDetails = {
  firstName: string;
  lastName: string;
};

export interface BASIC_USER_DATA {
  phoneNumber: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export interface USER_FEEDBACK {
  userId: number;
  starRating?: number;
  content?: string | undefined;
  spaceId?: number;
  q1?: string;
  a1?: string;
  q2?: string;
  a2?: string;
  q3?: string;
  a3?: string;
}

export enum LOGIN_STATUS {
  PENDING = "pending",
  LOGGED_IN = "loggedIn",
  LOGGED_OUT = "loggedOut",
}

export enum USER_LEGAL_POPUP {
  LEGAL_POPUP = "legalPopup",
  NO_LEGAL_POPUP = "noLegalPopup",
  NO_POPUP = "noPopup",
}
