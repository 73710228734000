import { PaymentMethod } from "@stripe/stripe-js";
import Axios from "axios";
import { Freedom_Pay_Request, GOOGLE_PAY } from "models/payment";
import { POURING_DATA } from "models/nina";

const { REACT_APP_BASE_API, REACT_APP_APPLICATION_TOKEN } = process.env;
const token = JSON.parse(localStorage.getItem("token") || "null");
export const axios = Axios.create({
  baseURL: REACT_APP_BASE_API,
  timeout: 70_000,
  headers: { applicationToken: REACT_APP_APPLICATION_TOKEN! },
});
axios.defaults.headers.common["userAuthToken"] = token;

export const getLocationResponse = async (spaceId: string) => {
  const { data } = await axios.get(`space/${spaceId}/status`);
  return data;
};
export const getCodeOTP = async (phoneNumber: string, isoCode: string) => {
  const { data } = await axios.post(`user/otp/trigger`, { phone: phoneNumber, isoCode });
  return data;
};
export const verifyCodeOTP = async (
  phoneNumber: string,
  isoCode: string,
  code: string,
  spaceId: string,
  redirectedFrom?: string,
  uuid?: string | null
) => {
  const { data } = await axios.post(`user/otp/verify`, {
    phone: phoneNumber,
    isoCode,
    code,
    spaceId,
    redirectedFrom,
    uuid: uuid || null,
  });
  return data;
};
export const getSocialLoginToken = async (tokenDetails: object) => {
  const { data } = await axios.post(`user/social/login`, tokenDetails);
  return data;
};
export const getUserTokenIsVerify = async (token: string, spaceId: number, uuid?: string | null) => {
  const { data } = await axios.post(`user/token/verify`, { token, spaceId, uuid: uuid || null });
  return data;
};
export const postGuestUser = async (spaceId: number) => {
  const { data } = await axios.post("user/guest", { spaceId });
  return data;
};

export const putUserDetails = async (userId: number, userDetails: object) => {
  const { data } = await axios.put(`/v2/user/${userId}`, userDetails);
  return data;
};
export const setPaymentGoogleResponse = async (userId: number, googlePayData: GOOGLE_PAY) => {
  const { data } = await axios.post(`user/${userId}/payment/google`, googlePayData);
  return data;
};
export const setPaymentAppleResponse = async (userId: number, paymentMethod: PaymentMethod) => {
  const { data } = await axios.post(`user/${userId}/payment/apple`, { paymentMethod });
  return data;
};
export const setCreditCardData = async (userId: number, paymentMethodId: string) => {
  const { data } = await axios.post(`user/${userId}/payment/cc`, { paymentMethodId });
  return data;
};
export const getClientSecret = async (userId: number) => {
  const { data } = await axios.get(`user/${userId}/client/secret`);
  return data;
};
export const getFreedomPayIframe = async (userId: number) => {
  const { data } = await axios.get(`/v2/freedompay/user/${userId}/iframe`);
  return data;
};
export const postFreedmPayPayment = async (userId: number, requestData: Freedom_Pay_Request) => {
  const { data } = await axios.post(`/v2/freedompay/user/${userId}/tokenize-pk`, requestData);
  return data;
};
export const startPouringSession = async (pouringData: POURING_DATA) => {
  const { data } = await axios.post(`pouringSession/start`, pouringData);
  return data;
};
export const stopPouringSession = async (ninaId: number, pouringSessionId: number) => {
  const { data } = await axios.post(`pouringSession/${pouringSessionId}/stop`, { ninaId });
  return data;
};
export const nullifyNinaDesiredState = async (uuid: string) => {
  const { data } = await axios.post(`/v2/nina/${uuid}/clear-desired`);
  return data;
};
export const getDisconnectFromChannel = async (userId: number) => {
  const { data } = await axios.post(`nina/${userId}/disconnect`);
  return data;
};
export const getClearNina = async (userId: number) => {
  const { data } = await axios.post(`nina/${userId}/clear`);
  return data;
};
export const getLiveNina = async (spaceId: string) => {
  const { data } = await axios.get(`space/${spaceId}/subscribe`);
  return data;
};
export const getUserTab = async (userId: number, page: number) => {
  const { data } = await axios.get(`tab/${userId}/find/all?skip=${page}`);
  return data;
};
export const getSpecificBillingHistoryTab = async (tabId: number) => {
  const { data } = await axios.get(`tab/${tabId}`);
  return data;
};
export const setPerkToUser = async (userId: number, venueId: number, spaceId: number, code: string) => {
  const { data } = await axios.post(`user/${userId}/perk`, { code, venueId, spaceId });
  return data;
};
export const getUserPerks = async (userId: number) => {
  const { data } = await axios.get(`user/${userId}/perks`);
  return data;
};
export const getPaymentMethods = async (userId: number) => {
  const { data } = await axios.get(`user/${userId}/paymentMethod/list`);
  return data;
};
export const putPrefferedPaymentMethod = async (userId: number, paymentMethodId: string) => {
  const { data } = await axios.put(`/user/${userId}/paymentMethod/default`, { paymentMethodId });
  return data;
};
export const deletePaymentMethod = async (paymentMethod: number, userId: number) => {
  const { data } = await axios.delete(`user/${userId}/paymentMethod/${paymentMethod}/remove`);
  return data;
};
export const sendHelpNotification = async (spaceId: string, content: string, userId?: number | null) => {
  const queryParam = !!userId ? `/?userId=${userId}` : "";
  const { data } = await axios.post(`/v2/space/${spaceId}/assistance${queryParam}`, { content });
  return data;
};
export const reportIssues = async (note: string, userId: number | null, spaceId?: number | null) => {
  const { data } = await axios.post(`/v2/space/contact-us`, { note, userId, spaceId });
  return data;
};
export const PostDrinksonMe = async (userId: number, spaceId: number) => {
  const { data } = await axios.post(`/space/${spaceId}/sponsor`, { userId });
  return data;
};
export const PutDrinksonMe = async (userId: number, spaceId: number) => {
  const { data } = await axios.put(`/space/${spaceId}/sponsor`, { userId });
  return data;
};
export const reportUserFeedback = async (
  userId: number,
  starRating?: number,
  content?: string | undefined,
  spaceId?: number,
  q1?: string,
  a1?: string,
  q2?: string,
  a2?: string,
  q3?: string,
  a3?: string
) => {
  const { data } = await axios.post(`/user/${userId}/feedback`, {
    starRating,
    content,
    spaceId,
    q1,
    a1,
    q2,
    a2,
    q3,
    a3,
  });
  return data;
};
