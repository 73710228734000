import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { LOCATION_RESPONSE } from "models/locations";
import { locationService } from "services/location.service";

interface props {
  setLocation: (value: React.SetStateAction<LOCATION_RESPONSE | null>) => void;
  spaceIdParam: string;
}

const useAppLocation = ({ spaceIdParam, setLocation }: props) => {
  const navigate = useNavigate();

  useQuery({
    queryKey: ["location", spaceIdParam],
    queryFn: () => locationService.getLocation(spaceIdParam),
    onSuccess: (data) => {
      const location = data?.locationStatus;
      setLocation({ ...location });
    },
    onError: (error: unknown) => {
      if (error instanceof Error && error.message === "Network Error") {
        return;
      }
      navigate("/selectSpace");
    },
    retry: false,
  });
};

export default useAppLocation;
