import { createContext, PropsWithChildren, useContext, useState } from "react";
import { NinaContextState } from "models/global";
import { ACCUMELATED_USAGE_RATE, NINA, NINA_STATUS, POURING_OPTION } from "models/nina";
import { usePubnub } from "hooks/usePubnub";
import { useAuthContext } from "./AuthContext";

export const GlobalNinaContext = createContext<NinaContextState>(null!);

export const NinaProvider = ({ children }: PropsWithChildren) => {
  const [ninaStatus, setNinaStatus] = useState<NINA_STATUS | null>(null);
  const [bottle, setBottle] = useState<NINA | null>(null);
  const [pouringOption, setPouringOption] = useState<POURING_OPTION | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [isThereAnyBottle, setIsThereAnyBottle] = useState<boolean>(false);
  const [accumulatedUsageRate, setAccumulatedUsageRate] = useState<ACCUMELATED_USAGE_RATE | null>(null);
  const [usageTimes, setUsageTimes] = useState<number>(0);

  const { setUser } = useAuthContext();
  const { connectToNinaChannel, disconnectFromNinaChannel } = usePubnub({
    setShowLoader,
    setNinaStatus,
    setAccumulatedUsageRate,
    setUser,
    setUsageTimes,
  });

  return (
    <GlobalNinaContext.Provider
      value={{
        ninaStatus,
        setNinaStatus,
        bottle,
        setBottle,
        pouringOption,
        setPouringOption,
        showLoader,
        setShowLoader,
        accumulatedUsageRate,
        setAccumulatedUsageRate,
        usageTimes,
        setUsageTimes,
        connectToNinaChannel,
        disconnectFromNinaChannel,
        isInitialLoad,
        setIsInitialLoad,
        isThereAnyBottle,
        setIsThereAnyBottle,
      }}
    >
      {children}
    </GlobalNinaContext.Provider>
  );
};

export const useNinaContext = () => useContext(GlobalNinaContext);
