import { Outlet } from "react-router-dom";
import Loader from "components/Loader";
import { Header } from "components/Navbar/Header";
import { useAuthContext } from "context/AuthContext";
import { useNinaContext } from "context/NinaContext";

export const Layout = () => {
  const { tokenVerficationLoading } = useAuthContext();
  const { isThereAnyBottle } = useNinaContext();

  return (
    <div id="nina" className={`${!isThereAnyBottle && "bg-card-back bg-no-repeat bg-cover"}`}>
      <Header />
      {tokenVerficationLoading ? <Loader /> : <Outlet />}
    </div>
  );
};

export const Fallback = () => {
  return (
    <div id="nina" className="bg-card-back bg-no-repeat bg-cover">
      <Header />
      <Loader />
    </div>
  );
};
