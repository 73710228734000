import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "components/ui/toaster";
import App from "./App";
import "./index.css";
import { LocationProvider } from "context/LocationContext";
import { BottomSheetProvider } from "context/BottomSheetContext";
import { AuthProvider } from "context/AuthContext";
import { NinaProvider } from "context/NinaContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: 1, refetchOnWindowFocus: false, refetchOnReconnect: false },
    mutations: { retry: 1 },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <LocationProvider>
          <BottomSheetProvider>
            <AuthProvider>
              <NinaProvider>
                <App />
                <Toaster />
              </NinaProvider>
            </AuthProvider>
          </BottomSheetProvider>
        </LocationProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
