import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import useAppLocation from "hooks/useAppLocation";
import { LocationContextState } from "models/global";
import { LOCATION_RESPONSE } from "models/locations";
import { stringHandler } from "utils/stringHandling";
import { useApplicationStatus } from "hooks/useApplicationStatus";

export const GlobalLocationContext = createContext<LocationContextState>(null!);

export const LocationProvider = ({ children }: PropsWithChildren) => {
  const [location, setLocation] = useState<LOCATION_RESPONSE | null>(null);
  const [redirectedFrom, setRedirectFrom] = useState<string | undefined>(undefined);
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const getSpaceId = useCallback(() => {
    return queryParams.get("spaceid") || stringHandler.getSpaceIdFromFBState(queryParams.get("state") || "");
  }, [queryParams]);
  const getUUID = useCallback(() => {
    return queryParams.get("uuid") || undefined;
  }, [queryParams]);
  const { applicationRoute } = useApplicationStatus();

  useAppLocation({ setLocation, spaceIdParam: getSpaceId() });

  return (
    <GlobalLocationContext.Provider
      value={{
        location,
        setLocation,
        getSpaceId,
        redirectedFrom,
        setRedirectFrom,
        applicationRoute,
        getUUID,
      }}
    >
      {children}
    </GlobalLocationContext.Provider>
  );
};

export const useLocationContext = () => useContext(GlobalLocationContext);
