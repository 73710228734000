import { useBottomSheetContext } from "context/BottomSheetContext";
import React, { Suspense, useEffect, useState } from "react";
const PaymentSheet = React.lazy(() => import("../PaymentBottomSheet"));

const LazyPaymentSheet = () => {
  const { showPaymentMethod } = useBottomSheetContext();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (showPaymentMethod && !isVisible) {
      setIsVisible(true);
    }
  }, [showPaymentMethod]);

  if (!isVisible) return null;
  return (
    <Suspense fallback={null}>
      <PaymentSheet />
    </Suspense>
  );
};

export default LazyPaymentSheet;
