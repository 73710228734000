import { useCallback } from "react";
import { Skeleton } from "components/ui/skeleton";
import { useAuthContext } from "context/AuthContext";

const MenuSkeleton = () => {
  const { isAuthenticated } = useAuthContext();
  const numberOfLineItems = isAuthenticated ? 5 : 2;

  const renderLineItems = useCallback(() => {
    const lineItems = [];
    for (let i = 0; i < numberOfLineItems; i++) {
      lineItems.push(
        <div key={i} className="flex gap-4">
          <Skeleton className="rounded-full h-8 w-8" />
          <Skeleton className="rounded-lg h-8 flex-1" />
        </div>
      );
    }
    return lineItems;
  }, [numberOfLineItems]);
  return (
    <div className="h-full bg-white flex flex-col gap-4 py-4 px-2">
      <div>
        <div className="flex w-full gap-6 mb-4">
          <Skeleton className="rounded-lg h-8 w-full" />
          <Skeleton className="rounded-lg h-8 w-full" />
        </div>
        <Skeleton className="rounded-lg h-8" />
      </div>

      <div className="flex flex-col mt-4 gap-4">{renderLineItems()}</div>

      <div className="mt-auto flex flex-col gap-4">
        <Skeleton className="rounded-lg h-8 w-full" />
        <div className="flex gap-4">
          <Skeleton className="rounded-full h-8 w-8" />
          <Skeleton className="rounded-lg h-8 flex-1" />
        </div>
      </div>
    </div>
  );
};

export default MenuSkeleton;
