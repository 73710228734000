import { createContext, PropsWithChildren, useContext, useState } from "react";
import { BottomSheetContextState } from "models/global";

export const GlobalBottomSheetContext = createContext<BottomSheetContextState>(null!);

export const BottomSheetProvider = ({ children }: PropsWithChildren) => {
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [showOTP, setShowOTP] = useState<boolean>(false);
  const [showPaymentMethod, setShowPaymentMethod] = useState<boolean>(false);
  const [showCreditCard, setShowCreditCard] = useState<boolean>(false);

  return (
    <GlobalBottomSheetContext.Provider
      value={{
        showLogin,
        setShowLogin,
        showOTP,
        setShowOTP,
        showPaymentMethod,
        setShowPaymentMethod,
        showCreditCard,
        setShowCreditCard,
      }}
    >
      {children}
    </GlobalBottomSheetContext.Provider>
  );
};

export const useBottomSheetContext = () => useContext(GlobalBottomSheetContext);
