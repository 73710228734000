import { useBottomSheetContext } from "context/BottomSheetContext";
import { useLocationContext } from "context/LocationContext";
import { LOGIN_STATUS, USER } from "models/user";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { axios } from "services/http.service";
import { loginService } from "services/login.service";
import { persistentService } from "services/persistent.service";

type props = {
  user: USER;
  setUser: React.Dispatch<React.SetStateAction<USER>>;
  setIsAdult: React.Dispatch<React.SetStateAction<boolean | null>>;
  setIsExtraInfoRequired: React.Dispatch<React.SetStateAction<boolean>>;
};

const useAuth = ({ user, setUser, setIsAdult, setIsExtraInfoRequired }: props) => {
  const { location, getUUID } = useLocationContext();
  const { setShowPaymentMethod } = useBottomSheetContext();

  const { mutate: verifyToken, isLoading: tokenVerficationLoading } = useMutation(
    ({ token, spaceId, uuid }: { token: string; spaceId: number; uuid?: string | null }) =>
      loginService.reconnectingUser(token, spaceId, uuid)
  );
  const { mutate: loginShowcase, isLoading: showcaseLoading } = useMutation((spaceId: number) =>
    loginService.createGuestUser(spaceId)
  );
  const connectToRoom = (shouldAddPayment: boolean) => {
    if (!!shouldAddPayment) {
      setShowPaymentMethod(true);
    }
  };

  //TODO: Use this to set if you want to gather extra info based on the venue
  const setExtraInfo = () => {};

  const handleLoginSuccess = (applicationUserData: any) => {
    const { token, isPaymentNeeded, userDetails } = applicationUserData;
    const { id, firstName, lastName, phone, email, isFeedbackGiven, userPerks } = userDetails;
    setUser({
      ...user,
      loginStatus: LOGIN_STATUS.LOGGED_IN,
      identityToken: token,
      id,
      firstName,
      lastName,
      phoneNumber: phone,
      email: email || "",
      isFeedbackGiven,
      userPerks,
    });
    setIsAdult(true);
    persistentService.setItem("token", token);
    axios.defaults.headers.common["userAuthToken"] = token;
    connectToRoom(isPaymentNeeded);
    setExtraInfo();
  };
  const handleLogOut = (isShowcase?: boolean) => {
    persistentService.removeItem("token");
    axios.defaults.headers.common["userAuthToken"] = "";
    setUser((prev) => ({
      phoneNumber: "",
      email: "",
      id: null,
      loginStatus: isShowcase ? LOGIN_STATUS.PENDING : LOGIN_STATUS.LOGGED_OUT,
      firstName: null,
      lastName: null,
      identityToken: "",
      userPerks: [],
      preLoginPerks: prev.preLoginPerks,
    }));
  };

  // Reconnecting user - checks local storage token
  useEffect(() => {
    const token = persistentService.getItem("token");
    const spaceId = location?.space.id;
    if (!token || !spaceId) return;
    const uuid = getUUID();
    verifyToken(
      { token, spaceId, uuid },
      {
        onSuccess: (data) => {
          const { userDetails } = data;
          setUser({ ...userDetails, phoneNumber: userDetails?.phone || "", loginStatus: LOGIN_STATUS.LOGGED_IN });
          persistentService.setItem("token", token);
          setIsAdult(true);
        },
        onError: () => {
          persistentService.removeItem("token");
          const showcaseVenueTypeId = 5; // if showcase then return pednding for login status
          handleLogOut(location?.venue.venueType.id === showcaseVenueTypeId);
        },
      }
    );
  }, [location?.space.id]);

  // Login for showcase venues
  useEffect(() => {
    if (user.loginStatus !== LOGIN_STATUS.PENDING) return;
    if (!location?.venue || !location.venue.venueType || !location?.space.id) return;
    const token = persistentService.getItem("token");
    if (token) return; // If there is already a user this will not create new users constantly
    const showcaseVenueTypeId = 5;
    if (location.venue.venueType.id === showcaseVenueTypeId) {
      loginShowcase(location.space.id, {
        onSuccess: (data) => {
          if (!data) return;
          const { userDetails } = data;
          setUser({
            ...userDetails,
            phoneNumber: userDetails?.phone || "",
            identityToken: data?.token,
            loginStatus: LOGIN_STATUS.LOGGED_IN,
          });
          setIsAdult(true);
        },
      });
    }
  }, [location?.space.id]);

  return {
    handleLoginSuccess,
    handleLogOut,
    tokenVerficationLoading: tokenVerficationLoading || showcaseLoading,
  };
};

export default useAuth;
