import React, { useEffect, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import splashAnimation from "Lottie/splashAnimation.json";
import { Dialog, DialogContent } from "components/ui/dialog";
import { useNinaContext } from "context/NinaContext";
import { useAuthContext } from "context/AuthContext";
import { useLocationContext } from "context/LocationContext";

type splashProps = {
  setSplashStarted: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SplashScreen = ({ setSplashStarted }: splashProps) => {
  const [splash, setSplash] = useState<boolean>(true);
  const { isInitialLoad } = useNinaContext();
  const { location } = useLocationContext();
  const { tokenVerficationLoading } = useAuthContext();

  useEffect(() => {
    setSplashStarted(true);
  }, []);

  return (
    <Dialog open={splash || (isInitialLoad && !!location) || tokenVerficationLoading}>
      <DialogContent
        overlayClassName="animate-none data-[state=closed]:!animate-none"
        className="z-[9999] h-full w-full p-0 m-0 border-0 transition-none animate-none data-[state=open]:!animate-none flex justify-center items-center lottie-container"
      >
        <Player
          src={splashAnimation}
          autoplay
          keepLastFrame
          loop={false}
          speed={0.7}
          className="h-full w-full"
          rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
          onEvent={(event) => {
            if (event === "complete") setSplash(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
