import { createPortal } from "react-dom";
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from "components/ui/toast";
import { useToast } from "./useToast";
import { ReactComponent as Check } from "assets/images/check.svg";
import { ReactComponent as X } from "assets/images/xError.svg";

export function Toaster() {
  const { toasts } = useToast();

  return createPortal(
    <ToastProvider>
      {toasts.map(function ({ id, title, description, variant, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="flex gap-4 items-center">
              {variant === "none" ? null : variant === "destructive" ? (
                <X className="h-8 w-8" />
              ) : (
                <Check className="h-8 w-8" />
              )}
              <div>
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && <ToastDescription>{description}</ToastDescription>}
              </div>
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>,
    document.body
  );
}
