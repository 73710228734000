import useAuth from "hooks/useAuth";
import { AuthContext } from "models/global";
import { LOGIN_STATUS, USER } from "models/user";
import { createContext, PropsWithChildren, useContext, useState } from "react";

export const GlobalAuthContext = createContext<AuthContext>(null!);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<USER>({
    id: null,
    phoneNumber: "",
    email: "",
    identityToken: "",
    loginStatus: LOGIN_STATUS.PENDING,
    firstName: "",
    lastName: "",
    userPerks: [],
    preLoginPerks: null,
  });
  const [isAdult, setIsAdult] = useState<boolean | null>(null);
  const [isExtraInfoRequired, setIsExtraInfoRequired] = useState<boolean>(false);
  const { handleLoginSuccess, handleLogOut, tokenVerficationLoading } = useAuth({
    user,
    setUser,
    setIsAdult,
    setIsExtraInfoRequired,
  });
  return (
    <GlobalAuthContext.Provider
      value={{
        user,
        setUser,
        isAdult,
        setIsAdult,
        isAuthenticated: user?.loginStatus === LOGIN_STATUS.LOGGED_IN,
        handleLoginSuccess,
        handleLogOut,
        tokenVerficationLoading,
        isExtraInfoRequired,
        setIsExtraInfoRequired,
      }}
    >
      {children}
    </GlobalAuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(GlobalAuthContext);
