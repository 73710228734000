const valueOrNa = (value: any, suffix?: string): any => {
  if (value === null || value === undefined || (value && typeof value === "string" && value.length === 0)) {
    return "N/A";
  }
  if (suffix) {
    return value + suffix;
  }
  return value;
};

const valueOrDefaultString = (value: any, defaultValue: string, suffix?: string): any => {
  if (value === null || value === undefined || (value && typeof value === "string" && value.length === 0)) {
    return defaultValue;
  }
  if (suffix) {
    return value + suffix;
  }
  return value;
};

const shortenString = (value: string, length: number, elipsis?: string): string => {
  return value.length > length ? value.slice(0, length) + (elipsis || "") : value;
};

const getSpaceIdFromFBState = (state: string) => {
  return state ? state?.split(":")[1] : "";
};

export const stringHandler = {
  valueOrNa,
  valueOrDefaultString,
  shortenString,
  getSpaceIdFromFBState,
};
