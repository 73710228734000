import React, { useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { useNinaContext } from "context/NinaContext";
import NinaLoader from "Lottie/nina preloader.json";

interface props {
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  hasExccededLoadTime?: React.Dispatch<React.SetStateAction<boolean>>;
  loadingText?: string;
}

const LOADERMAXLOOPTIME = 15;

const Loader = ({ setModalOpen, hasExccededLoadTime, loadingText }: props) => {
  const { setShowLoader } = useNinaContext();

  useEffect(() => {
    let timer = 0;
    var loaderTimeOutInterval = setInterval(() => {
      if (timer < LOADERMAXLOOPTIME) timer++;
      else {
        setShowLoader(false);
        setModalOpen && setModalOpen(false);
        hasExccededLoadTime && hasExccededLoadTime(true);
      }
    }, 1000);

    return () => {
      clearInterval(loaderTimeOutInterval);
    };
  }, []);

  return (
    <div className={"page-height flex flex-col items-center justify-center"}>
      <Player src={NinaLoader} autoplay loop speed={0.9} style={{ height: 100, width: 150 }} />
      {!!loadingText && <h3 className="text-xl text-center font-medium">{loadingText}</h3>}
    </div>
  );
};
export default Loader;
