import { getLiveNina, getLocationResponse } from "./http.service";

const getLocation = async (spaceId: string) => await getLocationResponse(spaceId);

const handleLiveNina = async (spaceId: string) => await getLiveNina(spaceId);

export const locationService = {
  getLocation,
  handleLiveNina,
};
