import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocationContext } from "context/LocationContext";

const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [spaceIdStorage, setSpaceIdStorage] = useState<string | null>(null);
  const navigate = useNavigate();
  const { getSpaceId } = useLocationContext();

  useEffect(() => {
    const spaceId = getSpaceId();
    setSpaceIdStorage(spaceId);
  }, [getSpaceId]);
  useEffect(() => {
    function handleOnline() {
      navigate({
        pathname: "/",
        search: `spaceid=${spaceIdStorage}`,
      });
      window.location.reload();
    }

    function handleOffline() {
      setIsOnline(false);
      navigate("/offline");
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [navigate]);
  return { isOnline };
};

export default useIsOnline;
