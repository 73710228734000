import { lazy, Suspense, useState } from "react";
import { ReactComponent as MenuIcon } from "assets/images/menu.svg";
import MenuSkeleton from "./MenuSkeleton";
import { Sheet, SheetContent } from "components/ui/sheet";
const SideMenuContent = lazy(() => import("./SideMenuContent"));

const HamburgerMenu = () => {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState<boolean>(false);

  return (
    <>
      <div
        className="active:opacity-60 cursor-pointer"
        onClick={() => {
          setShowHamburgerMenu(true);
        }}
      >
        <MenuIcon />
      </div>

      <Sheet
        open={showHamburgerMenu}
        onOpenChange={(open) => {
          if (!open) {
            setShowHamburgerMenu(false);
          }
        }}
      >
        <SheetContent side="left" className="md:max-w-md">
          <Suspense fallback={<MenuSkeleton />}>
            <SideMenuContent setShowHamburgerMenu={setShowHamburgerMenu} />
          </Suspense>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default HamburgerMenu;
