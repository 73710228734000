import { PERK } from "./perks";
import { SPIRIT_TYPE } from "./spirits";
import { BASIC_DATA } from "./utils";

export interface POURING_DATA {
  ninaId: number;
  userId: number;
  limitInMeasureUnit: number | null;
  spaceId: number;
  hasMixerRequested?: boolean | null;
}

export interface POURING_SUMMARY {
  bottleName: string;
  pouringSessionPouredAmount: number;
  pouringSessionsTotalCost: number;
  tabTotalCost: number | null;
}

export interface POURING_OPTION {
  amount: number;
  incrementer: number;
}

export interface POURING_SESSION {
  id: number;
  time: string;
  spirit: BASIC_DATA;
  pouredAmount: number;
  numberOfPouringScopes: number;
  isPerkApplied: boolean;
  sumPriceGross: number;
  sumPriceNet: number;
}

export interface ACCUMELATED_USAGE_RATE {
  pouringSessionsTotalCost: number;
  pouringSessionPouredAmount: number;
  tabTotalCost: number | null;
}

export interface NINA {
  bottleId: number;
  ninaId: number;
  ninaUuid: string;
  ninaChannel: string;
  originalPrice: number;
  pricePreLogin: number;
  pricePostLogin: number;
  pricePerMeasureUnit: number;
  name: string;
  bottleImg: string;
  description: string;
  perks: PERK[];
  promotion: string | null;
  caloriesPerShot: number | null;
  alcoholPercentage: number | null;
  countryOfOrigin: string | null;
  orderUrl: string | null;
  companyEstablishmentYear: number | null;
  isoCode: string | null;
  isTakenByOtherUser: boolean;
  isAsleep: boolean;
  spiritId: number;
  spiritType?: SPIRIT_TYPE;
}

export enum NINA_STATUS {
  STAND_BY = "standby",
  STAND_BY_MOUNT = "standby_mount",
  ACTIVE_MOUNT = "active_mount",
  POURING = "pouring",
  ERROR = "error",
  FINISH_POUR = "finish_pour",
}

export type LiveNinas = {
  ninas: NINA[];
  spaceChannelName: string;
  perks: PERK[];
};
